<template>
  <div class="circle"></div>

</template>

<script>
export default {
  mounted() {
    const circle = this.$el.querySelector('.circle')
    setInterval(() => {
      circle.style.transform = `translateX(-100%) translateY(100%)`
    }, 100)
  }
}
</script>

<style>
.circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: red;
  animation: move-animation 2s linear;
}

@keyframes move-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
